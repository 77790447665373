var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesDelivery.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesDelivery.id},on:{"organizationChanged":(organizationId) => {
          _vm.purchasesDelivery.organization.id = organizationId;
          _vm.purchasesDelivery.allowedLocations = [];
          _vm.purchasesDelivery.issuer.id = null;
          _vm.purchasesDelivery.purchasesOrder.id = null;
          _vm.purchasesDelivery.serviceCenter.id = null;
          _vm.purchasesDelivery.establishment.id = null;
          _vm.purchasesDelivery.cafeteria.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesDelivery.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesDelivery.organization.id},on:{"locationsChanged":(locations) => {
          _vm.purchasesDelivery.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"serviceCenter":_vm.purchasesDelivery.serviceCenter
          ? _vm.purchasesDelivery.serviceCenter.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesDelivery.organization.id},on:{"serviceCenterChanged":(serviceCenter) => {
          _vm.purchasesDelivery.serviceCenter.id = serviceCenter;
          _vm.purchasesDelivery.establishment.id = null;
          _vm.purchasesDelivery.cafeteria.id = null;
          _vm.purchasesDelivery.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.purchasesDelivery.establishment
          ? _vm.purchasesDelivery.establishment.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesDelivery.organization.id,"filterServiceCenter":_vm.purchasesDelivery.serviceCenter.id},on:{"establishmentChanged":(establishment) => {
          _vm.purchasesDelivery.establishment.id = establishment;
          _vm.purchasesDelivery.cafeteria.id = null;
          _vm.purchasesDelivery.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"cafeteria":_vm.purchasesDelivery.cafeteria ? _vm.purchasesDelivery.cafeteria.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesDelivery.organization.id,"filterServiceCenter":_vm.purchasesDelivery.serviceCenter.id,"filterEstablishment":_vm.purchasesDelivery.establishment.id},on:{"cafeteriaChanged":(cafeteria) => {
          _vm.purchasesDelivery.cafeteria.id = cafeteria;
          _vm.purchasesDelivery.purchasesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PURCHASES_ORDERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PURCHASES_ORDERS')} (*)`,"placeholder":_vm.$t('COMMON.PURCHASES_ORDERS')}},[_c('purchases-order-selector',{attrs:{"allowNone":true,"purchasesOrder":_vm.purchasesDelivery.purchasesOrder
          ? _vm.purchasesDelivery.purchasesOrder.id
          : null,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesDelivery.id,"filterOrganization":_vm.purchasesDelivery.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED,"filterServiceCenter":_vm.purchasesDelivery.serviceCenter.id,"filterEstablishment":_vm.purchasesDelivery.establishment.id,"filterCafeteria":_vm.purchasesDelivery.cafeteria.id},on:{"purchasesOrderChanged":(purchasesOrderId, purchasesOrder) => {
          _vm.purchasesDelivery.purchasesOrder.id = purchasesOrderId;
          if (purchasesOrder) {
            _vm.purchasesDelivery.issuer.id = purchasesOrder.issuer.id;
            if (purchasesOrder.destinationWarehouse) {
              _vm.purchasesDelivery.destinationWarehouse.id =
                purchasesOrder.destinationWarehouse.id;
            }
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.purchasesOrder}}),_c('base-input',{attrs:{"label":`${_vm.$t('PURCHASES_DELIVERIES.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('PURCHASES_DELIVERIES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.purchasesDelivery.expiration_time),callback:function ($$v) {_vm.$set(_vm.purchasesDelivery, "expiration_time", $$v)},expression:"purchasesDelivery.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":`${_vm.$t(`PURCHASES_DELIVERIES.SELECT_SUPPLIERS`)} (*)`}},[_c('supplier-selector',{attrs:{"allowNone":true,"supplier":_vm.purchasesDelivery.issuer.id,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesDelivery.organization.id,"disabled":!!_vm.purchasesDelivery.id},on:{"supplierChanged":(supplierId) => {
          _vm.purchasesDelivery.issuer.id = supplierId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.issuer}}),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.DESTINATION_WAREHOUSE`)}`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.purchasesDelivery.destinationWarehouse
          ? _vm.purchasesDelivery.destinationWarehouse.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesDelivery.organization.id,"disabled":!!_vm.purchasesDelivery.id},on:{"warehouseChanged":(warehouseId) => {
          _vm.purchasesDelivery.destinationWarehouse.id = warehouseId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.destinationWarehouse}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesDelivery.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesDelivery, "excerpt", $$v)},expression:"purchasesDelivery.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesDelivery.id ? _vm.$t("PURCHASES_DELIVERIES.EDIT_PURCHASES_DELIVERY") : _vm.$t("PURCHASES_DELIVERIES.ADD_PURCHASES_DELIVERY"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }